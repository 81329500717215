import { useAuth } from '../hooks/AuthContext';
import React, { useState, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import Category from './categorylist';
import UserLogin from './userlogin'; 
import { IoMdClose } from "react-icons/io";
import useFetchData from '../hooks/fetchcategory.js';
function App({topmsg,welcomemsg}) {
  const { setLoading, login, sessionId, sessionVid, } = useAuth();

  const vendorid = sessionVid;
  const { data } = useFetchData({ vendorid, setLoading });


  const [isPopupVisible, setIsPopupVisible] = useState(false);




  useEffect(() => {
    const isPopupShown = localStorage.getItem('popupShown');
    const isSessionActive = sessionStorage.getItem('sessionActive');
  
    if (!isPopupShown || !isSessionActive) {
      setIsPopupVisible(true);
      localStorage.setItem('popupShown', 'true'); 
      sessionStorage.setItem('sessionActive', 'true'); // Mark session active
    }
  }, []);

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="App">
     
      {login && sessionId && sessionVid ? (
        
        <>
      
          <Header topmsg={topmsg} />
      
          <div className='midouter'>
            <Category setLoading={setLoading} vendorid={sessionVid}/>


          </div>
 
          <Footer />
          { welcomemsg && welcomemsg !== "" && isPopupVisible && (
        <div className="popup-container mpopup1">
          <div className="popup-content">
         
            <div className="popup-message">
            <button className="close-popup-btn" onClick={closePopup}>
              <IoMdClose />
            </button>
              <div dangerouslySetInnerHTML={{ __html: welcomemsg }} />
        
            </div>
          </div>
        </div>
      )}
        </>

      ) : (
        <>
        <UserLogin />
        </>
      )}



    </div>
  );
}

export default App;

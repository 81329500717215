import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons'; 
import logo from '../assets/img/logo.png'; // Using import instead of require
import { RiMenu2Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'; // Import the up caret icon
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import useFetchData from '../hooks/fetchcategory.js';

import { useLocation } from 'react-router-dom';

function App({topmsg}) {
  const { loading, login, sessionId, sessionVid, showMessage, cartItems, handleLogout, setLoading } = useAuth();

  const vendorid = sessionVid;
  const { data } = useFetchData({ vendorid, setLoading });
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };



  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const response = await fetch(`https://bulkify.in/api/check_user.php?id=${sessionId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
        });
        if (!response.ok) {
          throw new Error('User deleted');
        }
      } catch (error) {
        console.log('User does not exist, logging out...');
        localStorage.removeItem('token');
        handleLogout();
      }
    };
  
    checkUserStatus();
  }, []);





// 
const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <>
    {loading && (
      <div className='loader_container'>
        <div className="loader"></div> 
      </div>
    )}
    <header>
   <div>
      {showMessage && <div className='successpro'>Product has been added to your cart </div>}
    </div>

      <div className=" text-center bg-white border-bottom">
        <div className="container">
          <div className="row gy-3 align-items-center">
            {/* Left elements */}
            <div className="col-lg-2 col-sm-4 col-7">
              
            {login && sessionId && sessionVid &&(
          <>
              <button className='mbshow mmenu sidebar-toggle' onClick={toggleSidebar}>
        <RiMenu2Fill />
      </button>


      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <button className='mbshow mmenu sidebar-toggle' onClick={toggleSidebar}>
        <IoMdClose />
      </button>
      <h4>Menu</h4>


   <div className='mblinks'>
   <Link to="/"  className=" links"> 

    Home</Link>

      <div className='dropdownw'>
      <button className='dropdown-togglew' onClick={toggleDropdown}>
      
         Categories {isDropdownOpen ? <FaCaretUp /> : < FaCaretDown />}
        </button>
        {isDropdownOpen && data.length > 0 && (
  <ul className="dropdown-menuw">
    {data.map(item => (
      <li key={item.id}> {/* Ensure you have a unique key for each item */}
        <Link to={`/${item.url}`} className="stlinks">
          {item.category}
        </Link>
      </li>
    ))}
  </ul>
)}
      </div>
     {/*  <Link to="/login"  className=" links"> Login</Link> */}
      <Link to="/cart"  className=" links"> Cart </Link>
      <Link to="/previous_orders"  className=" links"> previous orders </Link>
      {/* <Link to="/support" onClick={toggleSidebar}  className=" links"> Support </Link> */}
      <Link to="/support" onClick={toggleSidebar}  className=" links"> contact  </Link>
      {/* <Link to="/support#bankdetails1" onClick={toggleSidebar} className=" links" > bank details </Link>
      */}
      <Link to="/privacypolicy"  className=" links"> Privacy Policy </Link>
      <Link to="/profile"  className=" links"> Profile</Link>
      
      <Link to="" onClick={handleLogout}  className=" links"> Logout</Link>


   </div>


    </div>
</>
            )}
            
              <Link to="/"  className=" logo">
                <img
                  src={logo}
                  
                  alt="Logo" 
                />
              </Link>




            </div>
            
            {/* Center elements */}
            {login && sessionId && sessionVid && (
          <>

            <div className="col-lg-10 col-sm-8 col-5 d-flex justify-content-end order-lg-last">
     
            <div className="dropdown me-3 categd">
                <button
                  className="btn  btn1 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Category
                </button>
                {data.length > 0 && (
               <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton">
             {data.map(item => (
               
                  <li><a className="dropdown-item" href={`/${item.url}`}>{item.category}</a></li>
                ))}
                </ul>
              )}
              </div>
             
             
             
              {/* <Link
                to="/login"
                className="me-1 border rounded py-1 px-3 d-flex align-items-center"
               
              >
                <FontAwesomeIcon icon={faUserAlt} className="m-1 me-md-2" />
                <p className="d-none d-md-block mb-0">Sign in</p>
              </Link> */}
          
              <Link
                to="/cart"
                className="border cartbox rounded py-1 px-3 d-flex align-items-center"
          
              >
                <FontAwesomeIcon icon={faShoppingCart} className="m-1 me-md-2" />
                <p className="d-none d-md-block mb-0">My cart</p> <span className='cartcount'> {cartItems.length}</span> 
              </Link>

                
            </div>
     </>
            )}
          </div>
        </div>
      </div>
    </header>
    {topmsg && topmsg !== "" && (
    <div className="marquee-text">
   <div className="top-info-bar">
      <div className="fl-1 info-text">
      {topmsg}
      </div>
     <div className="fl-1 info-text">
     {topmsg}
      </div>
     <div className="fl-1 info-text">
     {topmsg}
      </div>
   </div>
</div>
)}
    </>
  );
}

export default App;

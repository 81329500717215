import Header from './header';
import Footer from './footer';
import React, { useState } from 'react';
import useFetchData from '../hooks/vendor/fetchorder.js';
import { Link, useNavigate } from 'react-router-dom';
import { FaRupeeSign } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import { MdDelete, MdEdit } from "react-icons/md";
import axios from 'axios';
function App() {
  const { vendorLogin, vendorSessionId, setLoading } = useAuth();
  const vid = vendorSessionId;
  const { data } = useFetchData({ vid, setLoading }); // Default to an empty array if data is undefined
  const navigate = useNavigate();
  const handleimgClick = (orderid) => {
    navigate(`/vendor/order-detail/${orderid}`);
  };

  const handleDelete = async (oid) => {
    try {
      const response = await axios.post(`https://bulkify.in/api/vendor/delete-order.php?formtype=deleteOrder&oid=${oid}`);
      const data = response.data;
  
      if (data.Massage === "Successfully Delete") {
        window.location.reload();
      }
   
    } catch (error) {
      console.error('Error checking mobile number:', error);
    }
  };

  return (
    <div className="App">
      <>
        <Header />

        <div className="midouter" id="cart">
          <div className="container">
            <div className="cart-page">
                <div className='d-flex justify-content-between'>
                    <h3>Orders ({data.length})</h3>
                   
                </div>
              
                {data.length > 0 && (
                <div className="table-responsive">
                  <table className="cart-table">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>User Mob.</th>
                        {data.some(item => item.total_amount != 0.00 )  && (
                          <>
                        <th>Amount</th>
                        <th>Total Item</th>
                        </>
                         )}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(item => (
                        <tr key={item.order_id} >
                          <td style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.order_id)}>
                          <span className=" itname">{item.order_id}</span>
                          </td>
                          <td style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.order_id)}>
                            <span className=" itname">{item.added_date}</span>
                          </td>
                          <td style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.order_id)}>
                            <span className=" itname">{item.usermob}<br></br>{item.name}</span>
                          </td>
                          {item.total_amount != 0.00  && (
                            <>
                          <td style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.order_id)}>
                            <span className=" itname">{item.total_amount}</span>
                          </td>
                          <td style={{'cursor': 'pointer'}} onClick={() => handleimgClick(item.order_id)}>
                          <span className=" itname">{item.totalitem}</span>
                          </td>
                          </>
                          )}
                        
                          
                          <td>
                            <span className="mgmax d-flex">
                              <button
                                className="delete me-3"
                                onClick={() => handleDelete(item.order_id)}
                              >
                                <MdDelete />
                              </button>
                              
                            </span>
                          </td>
                        </tr>
                      ))}
                 
                    </tbody>
                  </table>
                </div>
               )} 
             
            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}

export default App;

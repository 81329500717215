import Header from './header';
import Footer from './footer';
import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/fetchsearchproduct.js';
import { Link, useLocation  } from 'react-router-dom';
import { FaRupeeSign, FaShare  } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import UserLogin from './userlogin'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function App() {
    const location = useLocation();
    const { searchdata } = location.state || {};
  const { login, sessionId, sessionVid, addItemToCart, cartItems, showMessage, setLoading } = useAuth();
  const { data } = useFetchData({searchdata, setLoading});
  //console.log('useAuth returned:', { cartItems });
  const [vendormob, setVendormob] = useState(() => localStorage.getItem('vmobile') || null);
    const [quantity, setQuantity] = useState(1); 
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    const [selectedVariant, setSelectedVariant] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [selectedDisc, setSelectedDisc] = useState(null);
  
    const handleVariantChange = (event) => {
      const variantValue = event.target.value;
      const variantDetail = selectedItem.price_details.find(priceDetail => priceDetail.id === variantValue);
  
      if (variantDetail) {
        setSelectedVariant(variantValue);
        setSelectedPrice(variantDetail.price);
        setSelectedDisc(variantDetail.discount);
      }
    };

    const handleQuantityChange = (event) => {
      setQuantity(Number(event.target.value)); 
    };
 

    const options = {
      loop: true,
      margin: 10,
      nav: false,
      items: 1, 
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    };

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const togglePopup = (item = null) => {
    setSelectedItem(item);
    setSelectedVariant(null);
    setSelectedPrice(null);
    setSelectedDisc(null);
    setIsPopupVisible(!isPopupVisible);
  };


  const handleAddToCart = () => {
    
    const price = selectedPrice || (selectedItem.price_details.length > 0 && selectedItem.price_details[0].price) || null;
    const variant = selectedVariant || (selectedItem.price_details.length > 0 && selectedItem.price_details[0].id) || null;
    const discount = selectedDisc || (selectedItem.price_details.length > 0 && selectedItem.price_details[0].discount) || null;
    addItemToCart(selectedItem, quantity, price, variant,discount);
    togglePopup(selectedItem);
  }; 
     
  const shareOnWhatsApp = (productName, productImageUrl) => {
    const message = `Your Friend Share this product images for You. \nProduct Name: ${productName}\nIf You want to Buy Contact ${vendormob} \nProduct Image: ${productImageUrl}`;
    const whatsappUrl = `https://wa.me/?mtype=productshare&text=${encodeURIComponent(message)}`;
    
    // Open WhatsApp with the message
    window.open(whatsappUrl, '_blank');
  };
  return (
    <div className="App">
      {login && sessionId && sessionVid ? (
        
        <>
      
        
<Header showMessage={showMessage}/>


<div className='midouter'>

<div className='partners-bx mt-3'>
        <div className='container'>
          <div className="row title" style={{ marginBottom: "20px" }}>
            <div className="bheads mhead" data-aos="zoom-in">
            {searchdata}
            </div>
          </div>
        </div>
        <div className='container'>
        {data.length > 0 ? (
    <div className='productlist'   >
      {data.map(item => (
         


    <div className="titem item">
              <div className="mag-img">

              <a href={`https://wa.me/?mtype=productshare&text=Your Friend Share this product images for You. %0AProduct Name: ${item.title}%0AIf You want to Buy Contact ${vendormob} %0AProduct Image: https://bulkify.in/photo/product/${item.imgpath1}`} target="_blank"> <FaShare/></a>

              <a  href='#' onClick={() => togglePopup(item)} className='icontitle'>        <img src={`https://bulkify.in/photo/product/${item.imgpath1}`} className='img-fluid' alt="" title="" /> </a>
              
               <a href='#' onClick={() => togglePopup(item)} className='title'>  <b> {item.title}</b></a>
              
               <div className='dflexbox md2'>  <div className='price'>
  
  {item.price_details.length > 0 && item.price_details[0].discount > 0 && (
    <>
      
      <span>
      <FaRupeeSign />
        {(item.price_details[0].price - (item.price_details[0].price * item.price_details[0].discount) / 100).toFixed(2)}
        &nbsp; ({item.price_details[0].discount}% off) 
      </span>
    </>
  )}
  {item.price_details.length > 0 && (
  <span style={(item.price_details.length > 0 && item.price_details[0].discount > 0) ? { textDecoration: "line-through" } : {}}><FaRupeeSign /> {item.price_details[0].price } 
  </span>
   )}
</div>
               
                
               
                <div className='quantity'> <b>Qty.</b>       <select value={quantity} onChange={handleQuantityChange}>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={5}>6</option>
        <option value={5}>7</option>
        <option value={5}>8</option>
        <option value={5}>9</option>
        <option value={5}>10</option>
        {/* Add more options as needed */}
      </select>   </div> 
        
         </div>


               <div className='dflexbox'> <button  onClick={() => addItemToCart(
    item,
    quantity,
    item.price_details.length > 0 ? item.price_details[0].price : null,  // Default price if empty
    item.price_details.length > 0 ? item.price_details[0].id : null,  // Default id if empty
    item.price_details.length > 0 ? item.price_details[0].discount : null  // Default discount if empty
  )} className='btn'>
                            <b>Add to cart</b>
                          </button> 
         
               
         
      <a href="#" onClick={() => togglePopup(item)} className='detailbtn'>
        <b>View Detail</b>
      </a>

 

               
               
                 </div>
              </div>
            </div>

))}
</div>
):(
  <>
  <h3>No product Found.</h3>
  </>
)} 
    </div>
        </div>
      </div>



  <Footer/>



  {isPopupVisible && selectedItem && (
        <div className='popup' >
          <div className='popup-content p-0'>
            <span className='close' onClick={togglePopup}>&times;</span>
         <div className='row'>

         <div className='col-12' >        <OwlCarousel className='owl-theme' {...options}>
         {[...Array(10).keys()].map(i => {
    const imagePath = selectedItem[`imgpath${i + 1}`]; // Dynamically access imgpath1 to imgpath10
    if (imagePath) { // Check if the image path is not empty
      return (
        <div className='item' key={i}>
          <a href={`https://bulkify.in/photo/product/${imagePath}`} data-fancybox="imggallery" className="limg" data-caption="">
            <img src={`https://bulkify.in/photo/product/${imagePath}`} alt="" title="" />
          </a>
        </div>
      );
    }
    return null; // Don't render anything if imgpath is empty
  })}
        


</OwlCarousel> </div>

<div className='col-12' >

<div className="mdright p-2">
  <h4 className="modal-title">{selectedItem.title}</h4>



  <div className='row'>

  <div className="form-group col-12">
   
    
    {selectedItem.disc}
  </div>

  {selectedItem.price_details.some(priceDetail => priceDetail.variantName1 !== "") && (
  <div className="form-group col-6">
    <b>{selectedItem.price_details[0].variantName1}: </b>
    <select className="form-select" aria-label="Default select example" onChange={handleVariantChange}
            value={selectedVariant || selectedItem.price_details[0].variantValue1}>
      {selectedItem.price_details.map((priceDetail, index) => (
        priceDetail.variantName1 !== "" && (
          <option key={index} value={priceDetail.id}>
            {priceDetail.variantValue1}
          </option>
        )
      ))}
    </select>
  </div>
)}
      <div className="form-group col-6">
        <div className="quantity-controls mg100">
          <b>Qty: </b>
          <button className="sub" onClick={handleDecrement}>-</button>
          <span>{quantity}</span>
          <button className="add" onClick={handleIncrement}>+</button>
        </div>
      </div>

      <div className="form-group col-12 pricemd">
      {selectedItem.price_details.length > 0 && (
      <div className='price'>
      <b>Price: </b>

  {(selectedDisc || (selectedItem.price_details.length > 0 && selectedItem.price_details[0].discount)) > 0 && (
    <>
      <span>
        <FaRupeeSign />
        {(
          (selectedPrice || selectedItem.price_details[0].price) - 
          ((selectedPrice || selectedItem.price_details[0].price) * (selectedDisc || selectedItem.price_details[0].discount) / 100)
        ).toFixed(2)}
        &nbsp; ({selectedDisc || selectedItem.price_details[0].discount}% off) 
        &nbsp;
      </span>
    </>
  )}
  {selectedItem.price_details.length > 0 && (
    <>
    <span style={(selectedDisc || selectedItem.price_details[0].discount) > 0 ? { textDecoration: "line-through" } : {}}>
    <FaRupeeSign /> {selectedPrice || selectedItem.price_details[0].price}
  </span>
  </>
   )}
</div>
)}
        
      </div>
    </div>



  <div className="form-group">
    <button className="btn btn-primary "  onClick={handleAddToCart} > add to cart</button>
   
  </div>
</div>
  
   </div>
  
  
         </div>
          </div>
        </div>
      )}



</>
) : (
  <>
  <UserLogin />
  </>
)}





    </div>
  );
}

export default App;

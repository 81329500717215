import React, { useState,useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';
import useFetchData from '../hooks/vendor/fetchcategorydetail.js';

const CategoryForm = ({catid, setNewcat}) => {
    const { setLoading, vendorLogin, vendorSessionId, } = useAuth();
    const { data } = useFetchData({ catid, setLoading });
    const [values, setValues] = useState({
      id: catid,
      cname:"",
      priority:"",
      image:"",
      sortdsc:"",
    });
    const [newImage, setNewImage] = useState(null);
    useEffect(() => {
      if (data.length > 0) {
        setValues({
          ...values,
          cname:data[0]?.category,
          priority:data[0]?.sno,
          image:data[0]?.imgpath,
          sortdsc:data[0]?.disc,
        });
         
        
      }
    }, [data]);
 
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};
    
    if (!values.cname) newErrors.cname = 'Category Name is required';
    if (!values.priority) newErrors.priority = 'Priority is required';
    //if (!image) newErrors.image = 'Image is required';
    
    
    return newErrors;
  };

 const handleChange = (e) => {
    const { name, value, files } = e.target;
    
    if (name === 'image') {
      // If it's an image upload, we set the new image file
      setNewImage(files[0]);
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      const form = e.target;
      const formData = new FormData(form);
    formData.append('formtype', 'updateCategory');
    formData.append('vid', vendorSessionId);
    formData.append('catid', catid);
    if (newImage) {
      formData.append('image_edit', newImage); // Add new image file if it was uploaded
    }
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post('https://bulkify.in/api/vendor/update-category.php', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Successfully Update") {
                //setMessage(dataItem.Massage);
                setNewcat(true);
                setTimeout(() => {
                  navigate('/vendor/category-list', { state: { data: dataItem } });
        }, 1500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
  };

  return (
    <>
      <Header />
      <div id="login">
        <div className="login-container">
          <h1>Edit Category</h1>
          <form onSubmit={handleSubmit} action="" method="post" encType="multipart/form-data" >
            <div className="input-group">
              <label htmlFor="cname">Category Name</label>
              <input
                type="text"
                id="cname"
                name="cname"
                value={values.cname}
                onChange={handleChange}
                
              />
              {errors.cname && <p className="error">{errors.cname}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="priority">Priority</label>
              <input
                type="number"
                name="priority"
                id="priority"
                value={values.priority}
                onChange={handleChange}
                
              />
              {errors.priority && <p className="error">{errors.priority}</p>}
            </div>
 {/*
            <div className="input-group">
              <label htmlFor="image">Image</label>
              {values.image && (
                <div className="current-image">
                  <img src={`https://www.pakwaancandlelightdinner.com/new_app/photo/category/${values.image}`} alt="Current Category" style={{ width: '150px' }} />
                </div>
              )}
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleChange}
                
              />
              {errors.image && <p className="error">{errors.image}</p>}
            </div>

            <div className="input-group">
              <label htmlFor="sortdsc">Sort Description</label>
              <textarea
                
                id="sortdsc"
                name="sortdsc"
                value={values.sortdsc}
                onChange={handleChange}
                
              ></textarea>
              {errors.sortdsc && <p className="error">{errors.sortdsc}</p>}
            </div> */}

           
            <button type="submit" className="btn btn1">Update</button>

          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryForm;

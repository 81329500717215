import React, { useState, useEffect } from 'react';

import Header from './header';
import Footer from './footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import Compbankdetail from './comp_bankdetails.js';
function App() {
const [mobile, setMobile] = useState('');
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [remark, setRemark] = useState('');
 const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');

const navigate = useNavigate();
  const validateForm = () => {
    const newErrors = {};
    const mobileRegex = /^\d{10}$/; 
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!name){
        newErrors.name = 'Name Required';
      }

    if (!mobile || !mobileRegex.test(mobile)) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }

    if (!email || !emailRegex.test(email)) {
        newErrors.email = 'Please Type Valid Email';
      }

    if(!remark){
        newErrors.remark = 'Message Required';
      }
    


    return newErrors;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      const form = e.target;
      const formData = new FormData(form);
      try {
          const response = await axios.post(form.action, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          
          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item
            if (dataItem && dataItem.Massage === "Done") { // Ensure the message is "Done"
              navigate('/thanks', { 
                state: { 
                  message: '<p>We have Received Your Response. </p><p>One of Our Executive Will Contact You Soon.</p>' 
                } 
              });
            } 
           
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
    


  };







// contact






  return (
    <div className="App">


<>
<Header />

<div className='contactdetails-bx pt-3'  >
     
   
      <div class="container " > 
      <div class="bg-light" id='contactdeatil11'> 

             <h3 class="bheads mhead" > Help & Support</h3>

<h5> Harsh Jewellers </h5>

<h6> Address:- </h6>
             <p>  150, Kamla Nehru School Ki Gali  <br />
              Haldion Ka Rasta, Johari Bazar<br />
               Jaipur.(302003) </p>
             <h3 class="bheads mhead"  > Get Instant Answers to all your queries</h3>

 <h6 > Contact us on:- </h6>
<ul className='contactdtl'>
  <li>+919636511113  </li>
  <li>+919057777113  </li>
  <li>+919057177113 </li>
  <li>+919314069113 </li>
</ul>



<h6> Mail us on:- </h6>
<p> info@harshjewellersjaipur.com</p>
<h6> Web:- </h6>
<p> <a href="https://www.harshjewellersjaipur.com/" >www.harshjewellersjaipur.com</a> </p>

             <br></br>
            
             <div id="login" className="p-0 row">

             <div className="col-md-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14229.763095800952!2d75.80914556980133!3d26.92123766478861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db6b5278d04bf%3A0xa9364fb7326a3b06!2sHarsh%20Jewellers!5e0!3m2!1sen!2sin!4v1739510850664!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
<div className="col-md-6" >
<h3 class="bheads mhead" >Get Answers Over Mail</h3>
<div className="login-container  ">
                        
                        <form onSubmit={handleSubmit} action="https://bulkify.in/api/support.php" method="post" encType="multipart/form-data" >
                     <div className="input-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        
                      />
                      {errors.name && <p className="error">{errors.name}</p>}
                    </div>
                    <div className="input-group">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        
                      />
                      {errors.mobile && <p className="error">{errors.mobile}</p>}
                    </div>
        
                    <div className="input-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
        
                    <div className="input-group">
                      <label htmlFor="remark">Message</label>
                      <textarea
                        id="remark"
                        name="remark"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                      {errors.remark && <p className="error">{errors.remark}</p>}
                    </div>
        
                    <button type="submit" className="btn btn1">Submit </button>
                    {message && <p className="error text-center">{message}</p>}
                  </form>
                        </div>
</div>

          

             
             </div>
             
              </div>
              </div>


 

      </div>

    

      <Compbankdetail  />
 

  <Footer/>






</>






    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { FaRupeeSign } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import UserLogin from './userlogin'; 
import BankDetail from './bankdetailsforpayment'; 
import BankDetailQrcode from './bankdetailsforpaymentqr'; 
import axios from 'axios';
function App({topmsg,setNewodr}) {
  const { login, sessionId, sessionVid, cartItems = [], updateCartItemQuantity, removeItemFromCart } = useAuth();
  const navigate = useNavigate();
  console.log('useAuth returned:', { cartItems });

  const [message, setMessage] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [paymentDetailBank, setPaymentDetailBank] = useState(false);
  const [paymentDetailQr, setPaymentDetailQr] = useState(false);
  const [paymentDetailForm, setPaymentDetailForm] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('');

  const [txnnumber, setTxnnumber] = useState('');
  const [image, setImage] = useState('');


  const handleSelectMethod = (method) => {
    setSelectedMethod(method);
  };
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };
  
  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const itemPrice = Number(item.price) || 0; // Convert to number and fallback to 0
      const itemQuantity = Number(item.quantity) || 0; // Convert to number and fallback to 0
      return total + itemPrice * itemQuantity;
    }, 0).toFixed(2);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
    setSelectedMethod('');
    setPaymentDetailBank(false);
    setPaymentDetailQr(false);
    setPaymentDetailForm(false);
  };
   const todoNext = () => {
    if(selectedMethod==="NetBanking"){
      setPaymentDetailBank(true);
    }else if(selectedMethod==="ORCODE"){
      setPaymentDetailQr(true);
    }
    
  };
   const todoNext2 = () => {
    setPaymentDetailForm(true);
    setPaymentDetailBank(false);
    setPaymentDetailQr(false);
  };
  
  const handleCheckout = async () => {
    
    try {
      const formData = new FormData();
      formData.append('cartItems', JSON.stringify(cartItems)); 
      formData.append('total', calculateTotal());
      formData.append('customer_id', sessionId);
      formData.append('vid', sessionVid);
      formData.append('payment_mode', '');
      formData.append('txn_number', '');
  
      const response = await axios.post(
        'https://bulkify.in/api/checkout.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', 
          },
        }
      );
      if (response.status === 200) {
        setNewodr(true);
        // If checkout is successful, navigate to the confirmation or success page
        navigate('/thanks', { state: { message: '<p>Thank you for Order.</p> <p>We will review your order details.</p>' } });
      } else {
        console.error('Checkout failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
   
  }
  };

  const checkCheckout = async () => {
    const total = calculateTotal(); // Calculate the total
    if (total > 0) {
      console.error('Total amount is zero. Cannot proceed with payment.');
      setIsPopupVisible(true);
    }else{
      handleCheckout();
  }
  };
 
  const handlePlaceOrder = async () => {
    if(!txnnumber && !image){
      setMessage('Please Fill Ttransaction Id OR Screenshot');
    }else{
    try {
      const formData = new FormData();
      formData.append('cartItems', JSON.stringify(cartItems)); 
      formData.append('total', calculateTotal());
      formData.append('customer_id', sessionId);
      formData.append('vid', sessionVid);
      formData.append('payment_mode', selectedMethod);
      formData.append('txn_number', txnnumber);
  
      if (image) {
        formData.append('image', image); 
      }
  
      const response = await axios.post(
        'https://bulkify.in/api/checkout.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', 
          },
        }
      );
      if (response.status === 200) {
        // If checkout is successful, navigate to the confirmation or success page
        navigate('/thanks');
      } else {
        console.error('Checkout failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  }
  };
  
  return (
    <div className="App">
       {login && sessionId && sessionVid ? (
        
        <>
      <Header  topmsg={topmsg}/>
      <div className="midouter" id='cart'>
        <div className="container">
          <div className="cart-page">
            <h3>Your Shopping Cart</h3>
            {cartItems.length === 0 ? (
              <p className="empty-message">Your cart is empty!</p>
            ) : (
              <div className='table-responsive'>
                <table className="cart-table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Item</th>
                      <th>Quantity</th> 
                      {cartItems.some(item => item.price) && (
                            <>
                           
                      <th>Price</th>
                      <th>Total Price</th>
                      </>
                            )}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <img src={`https://bulkify.in/photo/product/${item.imgpath1}`} alt={item.title} className="product-image" />
                        </td>
                        <td><span className='mg150 itname'>{item.title}</span>
                        {item.price_details && item.price_details.length > 0 && 
                          item.price_details
                            .filter(detail => detail.id === item.variant)
                            .map(detail => (
                              <span key={detail.id} >
                            {detail.variantName1!='' && (    
                              <>
                             {detail.variantName1} - {detail.variantValue1} 
                             </>
                            )}
                            {detail.variantName2!='' && (    
                              <>
                            &nbsp;{detail.variantName2} - {detail.variantValue2} 
                             </>
                            )}
                         
                                {detail.discount > 0 && (
                        <>
                      &nbsp; ({detail.discount}% off) &nbsp;<span style={detail.discount > 0 ? { textDecoration: "line-through" } : {}} ><FaRupeeSign />{detail.price}</span>
                      </>
                      )}
                              </span>
                            ))
                        }
                        </td>
                        <td>
                          <div className="quantity-controls mg100">
                            <button className='sub' onClick={() => updateCartItemQuantity(item.id, item.quantity - 1)} disabled={item.quantity <= 1}>-</button>
                            <span>{item.quantity}</span>
                            <button className='add' onClick={() => updateCartItemQuantity(item.id, item.quantity + 1)}>+</button>
                          </div>
                        </td>  

                        {item.price !== null && (
                            <>
                              
                        <td>
                          <span className='mg100 price'>
                          
                            <FaRupeeSign />{(Number(item.price)).toFixed(2)}
                           
                          </span>
                        </td>
                        <td>
                          <span className='mg100 price'>
                          {item.price !== null && (
                            <>
                            <FaRupeeSign />{((Number(item.price) || 0) * (Number(item.quantity) || 0)).toFixed(2)}
                            </>
                            )}
                          </span>
                        </td>
                        </>
                            )}
                        <td>
                          <span className='mgmax'>
                            <button className='delete' onClick={() => removeItemFromCart(item.id)}>
                              <MdDelete />
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                    {cartItems.some(item => item.price) && (
                      <td colSpan="6">
                        <div className="cart-total">
                          <h2>Total: <FaRupeeSign />{calculateTotal()}</h2>
                        </div>
                      </td>
                    
                    )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div className="dflexbox mt-3">
            {cartItems.length === 0 ? (
              <Link className="w-100 detailbtn" to="/"> <b>continue shopping</b></Link>
            ) : (
              <>
               <Link onClick={checkCheckout}><b>checkout</b></Link>
               <Link className="detailbtn" to="/"> <b>continue shopping</b></Link>
              </>
           
            )}
            </div>
          </div>
        </div>
      </div>
      <Footer />












      {isPopupVisible &&  (
        <div className='popup' >
          <div className='popup-content'>
            <span className='close' onClick={togglePopup}>&times;</span>
         <div className='row'>

      
<div className='col-12 col-md-6' >
  {paymentDetailBank  ? (
    <>
    <BankDetail/>
    
    <button onClick={todoNext2}>Next</button>
    </>
      
  ): paymentDetailQr  ?(
    <>
    <BankDetailQrcode/>
    <button onClick={todoNext2}>Next</button>
    </>

  ): paymentDetailForm  ? (
    <div id="login">
        <div className="login-container">
          <h1>Please Fill Details</h1>
          <form  action="" method="post" encType="multipart/form-data" >
            <div className="input-group">
              <label htmlFor="cname">Transaction Id</label>
              <input
                type="text"
                id="txnnumber"
                name="txnnumber"
                value={txnnumber}
                onChange={(e) => setTxnnumber(e.target.value)}
              />
           
            </div>
            <div className="input-group">
              <label className='w-100 text-center'>OR</label>
            </div>
           <div className="input-group">
              <label htmlFor="cname">Upload Image</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleFileChange}
                
              />
           
            </div>
           
           {message && (
            <span style={{color: "red"}}>{message}</span>
           )}
            <button type="button" className="btn btn1" onClick={handlePlaceOrder}>Place Order</button>

          </form>
        </div>
      </div>
  ):(
<div className="popup-overlay">
      <div className="popup-container">
        <h2>Select Payment Method</h2>
        <div className="payment-options">
          
          <label>
            <input
              type="radio"
              value="NetBanking"
              checked={selectedMethod === 'NetBanking'}
              onChange={() => handleSelectMethod('NetBanking')}
            />
           &nbsp; NetBanking
          </label><br/>
          <label>
            <input
              type="radio"
              value="ORCODE"
              checked={selectedMethod === 'ORCODE'}
              onChange={() => handleSelectMethod('ORCODE')}
            />
           &nbsp; OR CODE
          </label><br/>
          <label>
            <input
              type="radio"
              value="COD"
              checked={selectedMethod === 'COD'}
              onChange={() => handleSelectMethod('COD')}
            />
           &nbsp; Cash on Delivery (COD)
          </label>
        </div>
        <div className="popup-buttons">
        {selectedMethod === "COD" ? (
          <button onClick={handleCheckout}>Place Order</button>
        ) : (selectedMethod === "NetBanking" || selectedMethod === "ORCODE") ? (
          <button onClick={todoNext}>Next</button>
        ) : null}
         
          
        </div>
      </div>
    </div>
  )}



    
   </div>
  
  
         </div>
          </div>
        </div>
      )}









      </>
) : (
  <>
  <UserLogin />
  </>
)}
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons'; 
import logo from '../assets/img/logo.png'; // Using import instead of require
import { RiMenu2Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'; // Import the up caret icon
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';

function App() {
  const { loading, vendorLogin, vendorSessionId, handleLogoutVendor, } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

const deleteaccount = () => {
  setIsdelete(true);
  };


 useEffect(() => {
       if (isdelete) {
       fetch(`https://bulkify.in/api/deletevendor.php?id=${vendorSessionId}`)
         .then(response => response.json())
         .then(data => {
           if (data.data === "Done") {
            handleLogoutVendor();
           }
         })
         .catch(error => console.error('Error fetching User Order Ids:', error));
       }
     }, [vendorSessionId,isdelete]);


  return (
    <>
    {loading && (
      <div className='loader_container'>
        <div className="loader"></div> 
      </div>
    )}
  
    <header>

      <div className=" text-center bg-white border-bottom">
        <div className="container">
          <div className="row gy-3 align-items-center">
            {/* Left elements */}
            <div className="col-lg-2 col-sm-4 col-7">
              
            {vendorLogin && vendorSessionId && (
          <>
              <button className='mbshow mmenu sidebar-toggle' onClick={toggleSidebar}>
        <RiMenu2Fill />
      </button>


      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <button className='mbshow mmenu sidebar-toggle' onClick={toggleSidebar}>
        <IoMdClose />
      </button>
      <h4>Menu</h4>


   <div className='mblinks'>
   <Link to="/vendor"  className=" links"> 

    Home</Link>
    <Link to="/vendor/category-list"  className=" links me-3">  Categories</Link>
    <Link to="/vendor/product-list"  className=" links me-3">  Products</Link>
    <Link to="/vendor/user-list"  className=" links me-3">  Users</Link>
    <Link to="/vendor/order-list"  className=" links me-3">  Orders</Link>
    <Link to="/vendor/bankdetail"  className=" links me-3">  Bank Detail</Link>
    <Link to="/vendor/contactdetail"  className=" links me-3">  Contact</Link>
    <Link to="/vendor/welcomepopup"  className=" links me-3">  Welcome</Link>
     
    
     
   </div>


    </div>
</>
            )}
            
              <Link to="/vendor"  className=" logo">
                <img
                  src={logo}
                  
                  alt="Logo" 
                />
              </Link>




            </div>
            
            {/* Center elements */}
            {vendorLogin && vendorSessionId && (
          <>

            <div className="col-lg-10 col-sm-8 col-5 d-flex justify-content-end order-lg-last">
            <div className="categd me-3">
            <Link to="/vendor"  className=" links me-3">  Home</Link>
            <Link to="/vendor/category-list"  className=" links me-3">  Categories</Link>
            <Link to="/vendor/product-list"  className=" links me-3">  Products</Link>
            <Link to="/vendor/user-list"  className=" links me-3">  Users</Link>
            <Link to="/vendor/order-list"  className=" links me-3">  Orders</Link>
            <Link to="/vendor/bankdetail"  className=" links me-3">  Bank Detail</Link>
             <Link to="/vendor/contactdetail"  className=" links me-3">  Contact</Link>
             <Link to="/vendor/welcomepopup"  className=" links me-3">  Welcome</Link>
           </div>
            <div className="dropdown me-3 ">
                <button
                  className="btn  btn1 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faUserAlt} className="m-1 me-md-2" />
                </button>
               
               <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                <li><Link to="/vendor/profile"  className=" dropdown-item">  Profile</Link></li>
                  <li><a className="dropdown-item" href='' onClick={handleLogoutVendor}>Logout</a></li>
                  <li><a className="dropdown-item" href='#' onClick={deleteaccount}>Delete Account</a></li>
           
                </ul>
             
              </div>
             
            
          
             


            </div>
     </>
            )}
          </div>
        </div>
      </div>
    </header>
    </>
  );
}

export default App;
